<template>
    <div class="login_page">
        <div class="center">
            <el-card class="box-card tc">
                <div slot="header" class="clearfix">
                    <h2>春城书籍搜索</h2>
                </div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo-ruleForm">
                    <el-form-item label="用户名" prop="name">
                        <el-input v-model="ruleForm.name" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <!--@keypress 触发键盘事件 enter回车 native触发原生事件  -->
                    <el-form-item label="密码：" prop="password">
                        <el-input v-model="ruleForm.password" type="password" placeholder="请输入密码"
                            @keypress.enter.native="hadle"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="login" @click="hadle">登录</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </div>
        <div class="kf_button" @click="open53">
            在线咨询
            <img src="../assets/img/kf.png" alt="">
        </div>
    </div>
</template>
<script defer="true" src="http://kf.usp-pb.com:80/im/0xuapa.html"></script>
<script>
import { post } from '../utils/http'
import { setToken } from '../utils/auth'
export default {
    data() {
        return {
            ruleForm: {
                name: "",
                password: "",//此处是接口对应的名字根据后端的名字修改
            },
            rules: {
                name: [
                    { required: true, message: "用户名不能为空", trigger: 'blur' },
                    { min: 3, max: 5, message: "长度在3到5个字符", trigger: 'blur' }
                ],
                password: [
                    { required: true, message: "密码不能为空", trigger: 'blur' },
                    { pattern: /^\d{6}$/, message: "密码是6位纯数字", trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        hadle() {
            this.$refs["ruleForm"].validate((hadle) => {
                if (hadle) {
                    post("/login", this.ruleForm).then((res) => {
                        setToken(res.token)
                        sessionStorage.setItem("nickname", res.nickname)
                        this.$router.push('/')
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            })
        },
        open53() {
            window.open("http://kf.usp-pb.com/im/text/0xuapa.html", '_blank', "height=600,width=800, top=300, left=0, toolbar=no, menubar=no,location=no")
        },
    }
}
</script>

<style lang="less" scoped>
.login_page{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .center{
        width: 480px;
    }
    .kf_button{
        position: fixed;
        right: 10px;
        bottom: 200px;
        z-index: 100;
        background: #409EFF;
        width: 40px;
        height: 140px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        letter-spacing: 2px;
        color: #ffffff;
        writing-mode: vertical-rl;
        cursor: pointer;
        line-height: 20px;
        img{
            width: 30px;
            margin-top: 10px;
        }
    }

}


.login {
    width: 100%;
}
@media screen and(max-width: 759px){
    .center{
        margin: 0 10px;
        width: 80%;
    }
}

</style>